<template>

  <!-- {{user}} -->
  <div class="col-lg-10 col-md-10">
    <iq-card body-class="chat-page">
      <template v-slot:body>
        <div class="chat-data-block">
          <div class="row">
            <div :style="`${leftBar ? 'display:block ;' : 'display:none;'}`" class="chat-data-left scroller col-lg-4">
              <div>
                <div class="chat-sidebar-channel scroller mt-2 ps-2" v-loading="chatListLoading">
                  <div v-if="!list.length" class="d-flex justify-content-center align-items-center">
                    {{ $t('沒有任何好友') }}
                  </div>
                  <div class="nav nav-pills flex-column iq-chat-ui p-1" id="chat-list-data" v-for="(item, index) in list" :key="index" @click="showLoading">
                    <div @click="openUser(item)" :item="item" :id="`v-pills-${item.id}`" :href="`#${item.user_account}`" :ariaControls="`v-pills-${item.id}`" role="tab"
                      class="d-flex justify-content-between chat-list-card">
                      <div class="d-flex align-items-center">
                        <div class="avatar me-2">
                          <img :src="item.avatar" :alt="item.avatar" class="avatar-40" />
                          <!-- 上下線狀態 -->
                          <!-- <span class="avatar-status">
                                  <i class="ri-checkbox-blank-circle-fill"
                                    :class="item.isActive ? 'text-success' :'text-warning'" />
                                </span> -->
                        </div>
                        <div class="chat-sidebar-name">
                          <h6 class="mb-0">{{ item.user_name }}</h6>
                          <span>{{ item.user_account }}</span>
                          <div v-if="!item.is_read" class="msg-black msg-n">
                            {{ item.content }}
                          </div>
                          <div v-else class="msg-n">{{ item.content }}</div>
                        </div>
                      </div>
                      <div class="p-1 d-flex align-items-center">
                        <div class="d-flex chat-meta float-right text-center me-1">
                          <div class="text-nowrap msg-time">
                            {{
                                formatDate(
                                  `${item.created_date} ${item.created_time}`
                                )
                              }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Pagination ariaLabel="Page navigation example">
                    <!-- <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">Next</a></li> -->
                  </Pagination>
                </div>
              </div>
            </div>
            <div class="col-lg-8 chat-data p-0 chat-data-right" :style="`${rightBar ? 'display:block ;' : 'display:none;'
                  }  background: url(${require('../../../assets/images/page-img/100.jpg')}) no-repeat scroll center center;background-size: cover;`">
              <tab-content id="v-pills-tabContent">
                <tab-content-item :active="tabStartItem" id="v-pills-default" aria-labelled-by="v-pills-default">
                  <div class="chat-start">
                    <span class="iq-start-icon text-primary">
                      <i class="ri-message-3-line"></i>
                    </span>
                    <button id="chat-start" class="btn bg-white mt-3">
                      {{ $t('選擇任何對話或發送新消息') }}
                    </button>
                  </div>
                </tab-content-item>

                <tab-content-item :active="tabUserItem" id="v-pills-home" aria-labelled-by="v-pills-default">
                  <div class="chat-head">
                    <header class="d-flex justify-content-between align-items-center bg-white pt-2 pe-2 pb-2">
                      <div class="chat-header-icons d-flex">
                        <a @click="back" class="chat-icon-phone bg-soft-primary"><i class="ri-arrow-left-line me-0" /></a>
                      </div>
                      <ToggleButton :mini-toggle="true" :close-button="false" toggleShow="#chat-user-detail-popup" mediaClass="chat-user-profile">
                        <template v-slot:media>
                          <span class="pe-1">{{
                              currentUser.user_name
                            }}</span>
                          <img :src="currentUser.avatar" alt="avatar" class="avatar-50 cursor_pointer" @click="$router.push({path: `/${currentUser.user_account}`})" />
                          <span class="avatar-status">
                            <i class="ri-checkbox-blank-circle-fill text-success" />
                          </span>
                        </template>
                      </ToggleButton>
                    </header>
                  </div>
                  <div class="chat-content scroller" id="chat" v-loading="commomData.listLoader">
                    <!-- {{ detail }} -->
                    <div v-for="(item, index) in detail" :key="index">
                      <div class="chat chat-right me-1" v-if="item.me">
                        <div class="d-flex justify-content-end align-content-end">
                          <div v-if="item.chat_media?.length >= 1" class="text-wrapper-img">
                            <div class="block">
                              <el-carousel v-viewer indicator-position="outside" height="205px" width="500px" :autoplay="false" arrow="always">
                                <el-carousel-item v-for="(media, index2) in item.chat_media" :key="index2">
                                  <img v-if="media.media_type === 'photo'" :src="media.media_img" style="width: 100%;" class="img_hide" />
                                  <div v-if="media.media_type === 'photo'" class="bg_img" :style="`background-image: url(${media.media_square_img});background-size: cover`">
                                  </div>
                                  <!-- <videoPlay v-if="media.media_type === 'video'" :ref="`videoPlayer`" v-bind="getOption(media)" /> -->
                                  <!-- <video controls controlsList="nodownload" playsinline webkit-playsinline="true" v-if="media.media_type === 'video'" :ref="`videoPlayer`" style="width: 100%;height: 100%" :poster="media.media_square_img" >
                                    <source :src="media.media_img" />
                                  </video> -->
                                  <VideoPlayer v-if="media.media_type === 'video'" :ref="`videoPlayer_${index}`" :options="videoOptions(media)" :setIndex="`right${index}`" @setVideowWatermark="setVideowWatermark"
                                    style="width: 100%;height: 100% !important;" />
                                </el-carousel-item>
                              </el-carousel>
                            </div>
                          </div>
                        </div>
                        <div class="chat d-flex other-user">
                          <div class="chat-detail">
                            <div class="chat-message">
                              <div v-if="item.content" class="text-wrapper" style="float: right;">
                                <span>{{ item.content }}</span>
                              </div>
                              <div></div>
                              <div v-if="item.is_pay === 1" class="mt-1 text-danger" style="font-size: 12px;">
                                {{ $t('price') }}: {{ item.money }} ({{ item.is_lock === 1 ? '尚未購買' : '已購買' }})
                              </div>
                              <span class="chat-time mt-1">{{
                                  item.time
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="chat chat-left" :key="index" v-if="!item.me">
                        <div class="chat-user">
                          <a class="avatar m-0">
                            <img :src="currentUser.avatar" alt="avatar" class="avatar-35 cursor_pointer" @click="$router.push({path: `/${currentUser.user_account}`})" />
                          </a>
                          <span class="chat-time mt-1">{{ item.time }}</span>
                        </div>
                        <div v-if="item.is_lock" class="chat-detail">
                          <div class="chat-lock-message">
                            <div class="d-flex justify-content-center align-items-center ">
                              <div>
                                <el-icon :size="35">
                                  <Lock />
                                </el-icon>
                              </div>
                            </div>
                            <div class="d-flex justify-content-center  align-items-end">
                              <el-button @click="purchas(item)" type="primary" round> {{ $t('解除鎖定') }} $ {{ item.money }}
                              </el-button>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div v-if="item.chat_media?.length >= 1" class="text-wrapper-img">
                            <div class="block">
                              <el-carousel v-viewer indicator-position="outside" height="205px" width="500px" :autoplay="false" arrow="always">
                                <el-carousel-item v-for="(media, index2) in item.chat_media" :key="index2">
                                  <img v-if="media.media_type === 'photo'" :src="media.media_img" style="width: 100%;" class="img_hide" />
                                  <div v-if="media.media_type === 'photo'" class="bg_img" :style="`background-image: url(${media.media_square_img});background-size: cover`">
                                  </div>
                                  <!-- <el-image v-if="media.media_type === 'photo'" :src="media.media_square_img" fit="fill" /> -->
                                  <!-- <video controls controlsList="nodownload" playsinline webkit-playsinline="true" v-if="media.media_type === 'video'" :ref="`videoPlayer`" style="width: 100%;height: 100%" :poster="media.media_square_img" >
                                    <source :src="media.media_img" />
                                  </video> -->
                                  <VideoPlayer v-if="media.media_type === 'video'" :ref="`videoPlayer_${index}`" :options="videoOptions(media)" :setIndex="`left${index}`" @setVideowWatermark="setVideowWatermark"
                                    style="width: 100%;height: 100% !important;" />
                                  <!-- <videoPlay v-if="media.media_type === 'video'" :ref="`videoPlayer`" v-bind="getOption(media)" /> -->
                                </el-carousel-item>
                              </el-carousel>
                            </div>
                          </div>
                          <div class="chat-message">
                            <span>{{ item.content }}</span>

                            <div v-if="item.is_pay === 1" class="mt-1 text-danger">
                              {{ $t('price') }}: {{ item.money }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="chat-footer p-1 bg-white">
                    <div class="d-flex align-items-center">
                      <!-- 圖像-->
                      <hr v-if="postForm.msg_media.length || selectMedia.length || uploadLoading" />
                      <div v-if="postForm.msg_media.length || selectMedia.length || uploadLoading">
                        <div>
                          <div>
                            <!-- {{message_template_media}} -->
                            <ul class="el-upload-list el-upload-list--picture-card">
                              <!-- upload Percent -->
                              <li v-for="(m, index) in postForm.msg_media" :key="index" class="el-upload-list__item is-success" tabindex="0">
                                <img class="el-upload-list__item-thumbnail" :src="m.media_url" alt="" />
                                <label class="el-upload-list__item-status-label">
                                  <i class="el-icon el-icon--upload-success el-icon--check">
                                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                      <path fill="currentColor" d="M406.656 706.944 195.84 496.256a32 32 0 1 0-45.248 45.248l256 256 512-512a32 32 0 0 0-45.248-45.248L406.592 706.944z">
                                      </path>
                                    </svg>
                                  </i>
                                </label>
                                <i class="el-icon el-icon--close">
                                  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="currentColor"
                                      d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                                    </path>
                                  </svg>
                                </i>
                                <span class="el-upload-list__item-actions">
                                  <span class="el-upload-list__item-preview">
                                    <i @click="onPreview(m)" class="el-icon el-icon--zoom-in">
                                      <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor"
                                          d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704zm-32-384v-96a32 32 0 0 1 64 0v96h96a32 32 0 0 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64h96z">
                                        </path>
                                      </svg>
                                    </i>
                                  </span>
                                  <span @click="onDelete(index)" class="el-upload-list__item-delete">
                                    <i class="el-icon el-icon--delete">
                                      <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor"
                                          d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z">
                                        </path>
                                      </svg>
                                    </i>
                                  </span>
                                </span>
                              </li>
                              <!-- 媒體庫 -->
                              <li v-for="(m, index) in selectMedia" :key="`media${m.media_id}`" class="el-upload-list__item is-success" tabindex="0">
                                <img class="el-upload-list__item-thumbnail" :src="m.media_square_url" alt="" />
                                <label class="el-upload-list__item-status-label">
                                  <i class="el-icon el-icon--upload-success el-icon--check">
                                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                      <path fill="currentColor" d="M406.656 706.944 195.84 496.256a32 32 0 1 0-45.248 45.248l256 256 512-512a32 32 0 0 0-45.248-45.248L406.592 706.944z">
                                      </path>
                                    </svg>
                                  </i>
                                </label>
                                <i class="el-icon el-icon--close">
                                  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="currentColor"
                                      d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                                    </path>
                                  </svg>
                                </i>
                                <span class="el-upload-list__item-actions">
                                  <span class="el-upload-list__item-preview">
                                    <i @click="onMediaPreview(m)" class="el-icon el-icon--zoom-in">
                                      <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor"
                                          d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704zm-32-384v-96a32 32 0 0 1 64 0v96h96a32 32 0 0 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64h96z">
                                        </path>
                                      </svg>
                                    </i>
                                  </span>
                                  <span @click="onMediaDelete(index)" class="el-upload-list__item-delete">
                                    <i class="el-icon el-icon--delete">
                                      <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor"
                                          d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z">
                                        </path>
                                      </svg>
                                    </i>
                                  </span>
                                </span>
                              </li>
                              <li v-if="uploadLoading" v-loading="uploadLoading" class="el-upload-list__item is-success" tabindex="0">
                                <div class="text-primary text-center" style="font-size: 16px; z-index: 99999">
                                  <div class="p-1">{{ uploadPercent }}</div>
                                </div>
                              </li>
                            </ul>
                            <el-dialog v-model="dialogVisible" width="80%">
                              <div v-if="dialogType === 'video'" class="text-center onPreview-video">
                                <video controls>
                                  <source :src="dialogUrl" type="video/mp4" />
                                </video>
                              </div>
                              <div v-else class="text-center">
                                <img :src="dialogUrl" fit="fill" />
                                <!-- <template #placeholder>
                                    <div class="image-slot">
                                      Loading<span class="dot">...</span>
                                    </div>
                                  </template> -->

                              </div>
                            </el-dialog>
                          </div>
                        </div>
                      </div>
                      <!-- <div>圖像</div> -->
                    </div>

                    <el-alert v-if="isSetPrice" :title="`指定一則訊息價格 $${form.price}`" @close="removePrice" type="success" class="mb-1">
                    </el-alert>
                    <el-alert v-if="isChat" :title="`群發訊息，已選擇 ${selectChatId.length} 位粉絲`" @close="removeChat" type="info" class="mb-1">
                    </el-alert>
                    <form class="d-flex align-items-center" action="javascript:void(0);">
                      <textarea :disabled="textDisabled" v-on:keyup.enter="enterSend" v-model="msg" type="text" class="form-control me-3" placeholder="Type your message" />
                      <button @click="send" :disabled="btnDisabled || uploadLoading" type="submit"
                          class="btn btn-primary d-flex align-items-center p-2">
                          <i class="far fa-paper-plane"></i><span class="d-none d-lg-block ms-1">Send</span>
                        </button>
                      </form>
                    </div>

                    <!-- 訊息，附加圖片，一些功能 -->
                    <div class="chat-attagement d-flex bg-white justify-content-between ps-1">
                      <ul class="post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                        <el-upload :show-file-list="false" :headers="{ authorization: token }"
                          :accept="commomData.uploadOption.accept" :data="{ type: 'chat_media' }"
                          :action="commomData.uploadOption.url" :on-success="handleAvatarSuccess"
                          :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :on-progress="handleProgress"
                          :before-upload="handleBeforeUpload" multiple>
                          <el-tooltip class="item" effect="dark" :content="`${$t('label.uploadImage')}${$t('label.or')}${$t('label.uploadVideo')}`" placement="bottom">
                            <li class="mb-md-0">
                              <button type="button" class="btn btn-circle-sm mb-1 me-1">
                                <i class="far ri-image-line fa-fw me-0"></i>
                              </button>
                            </li>
                          </el-tooltip>
                          <!-- <el-tooltip class="item" effect="dark" :content="$t('label.uploadVideo')" placement="bottom">
                            <li class="mb-md-0">
                              <button type="button" class="btn btn-circle-sm mb-1 me-1">
                                <i class="far ri-video-line fa-fw me-0"></i>
                              </button>
                            </li>
                          </el-tooltip> -->
                        </el-upload>
                        <el-tooltip class="item" effect="dark" content="媒體庫圖片" placement="bottom">
                          <li class="mb-md-0">
                            <button type="button" class="btn btn-circle-sm mb-1 me-1 hide_input" @click="openMediaModal();getMediaElement();">
                              <!-- <i class="far ri-image-line fa-fw me-0"></i> -->
                              <img src="@/assets/images/media.png" style="width: 20px;height: 20px;" alt="">
                            </button>
                          </li>
                        </el-tooltip>
                        <el-tooltip v-if="user.is_agent" class="item" effect="dark" content="含有小費的紛絲私訊"
                          placement="bottom">
                          <div>
                            <li class="mb-md-0">
                              <button type="button" class="btn btn-circle-sm mb-1 me-1" @click="priceDialog = true">
                                <!-- <i class="far mingcute:usd-coin-usdc-line fa-fw me-0"></i> -->
                                <i class="fas fa-comment-dollar fa-fw me-0" aria-hidden="true"></i>
                              </button>
                            </li>
                          </div>
                        </el-tooltip>
                        <el-tooltip v-if="user.is_agent" class="item" effect="dark" content="群發訊息" placement="bottom">
                          <div>
                            <li class="mb-md-0">
                              <button type="button" class="btn btn-circle-sm mb-1 me-1" @click="chatListDialog = true">
                                <!-- <i class="far mingcute:usd-coin-usdc-line fa-fw me-0"></i> -->
                                <i class="fas fa-comments fa-fw me-0" aria-hidden="true"></i>
                              </button>
                            </li>
                          </div>
                        </el-tooltip>
                      </ul>
                    </div>
                  </tab-content-item>
                </tab-content>
              </div>
              <div v-show="chatStart" class="col-lg-8 chat-data p-0 chat-data-right"
                :style="`background: url(${require('../../../assets/images/page-img/100.jpg')}) no-repeat scroll center center;background-size: cover;`">
                <div class="tab-content">
                  <div class="tab-pane fade">
                    <div class="chat-start" v-show="chatStart">
                      <span class="iq-start-icon text-primary">
                        <i class="ri-message-3-line"></i></span>
                      <button id="chat-start" class="btn bg-white mt-3">
                        {{ $t('選擇任何對話或發送新消息') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </iq-card>
    </div>
    <el-dialog v-model="priceDialog" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false"
      title="消息價格" :width="`${w}`">
      <span>{{ dialogTipPayTitle$ }}</span>
      <el-form ref="ruleFormRef" :model="form">
        <el-form-item class="w-100" :rules="[
          { required: true, message: $t('messages.required') },
          { type: 'number', message: 'Is Number' }
        ]" prop="price">
          <el-input v-model.number="form.price" type="number" :placeholder="$t('label.minimum#$50TWD')"></el-input>
        </el-form-item>
        <!-- <div class="pay-desc">
          <span class="ps-1">{{ $t('label.paymentDescription') }}</span>
        </div> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button :loading="onLoading" text @click="priceDialog = false">
            {{ $t('label.cancel') }}
          </el-button>
          <el-button :loading="onLoading" text type="primary" @click="submit()">
            確定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="chatListDialog" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false"
      title="選擇用戶" :width="`${w}`">
      <!-- <div class="card-body iq-card iq-card-block iq-card-stretch iq-card-height"> -->
      <!-- <div class="search-field">
          <el-input v-model="keyWork" clearable :placeholder="'搜尋用戶'" class="input-with-select" size="large"
            @input="search">
            <template #prepend>
              <el-icon>
                <Search />
              </el-icon>
            </template>
          </el-input>
        </div> -->
      <div v-if="groupList?.length === 0" v-loading="true"></div>
      <tab-content v-else  id="pills-tabContent-2">
        <el-tabs v-model="activeName" class="demo-tabs p-0">
          <div class="ps-2">
            <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="all">
              Check all
              </el-checkbox>
          </div>
          <el-tab-pane :label="'粉絲'" name="first">
            <el-checkbox-group v-model="selectChatId">
              <div v-for="(r, index) in groupList" :key="index" class="col-12 d-flex pb-1 search-wrap">
                <div class="p-2">
                  <el-checkbox :label="r.chat_id" />
                </div>
                <el-avatar :size="50" :src="r.account" @error="errorHandler">
                  <img :src="r.avatar" style="width: 100%;" />
                </el-avatar>
                <div class="account-list m-1">
                  <p class="account-p1 mb-0 ms-2 text-dark">{{ r.user_name }}</p>
                  <p class="account-p2 mb-0 ms-2 text-secondary">{{ r.account }}</p>
                </div>
              </div>
            </el-checkbox-group>
          </el-tab-pane>
        </el-tabs>
        
      </tab-content>
      <!-- </div> -->
      <template #footer>
        <span class="dialog-footer" >
          <el-button :loading="onLoading" text @click="actionChat(false)">
            {{ $t('label.cancel') }}
          </el-button>
          <el-button v-if="groupList?.length > 0" :loading="onLoading" text type="primary" @click="actionChat(true)">
            確定
          </el-button>
        </span>
      </template>
    </el-dialog>
  <MsgPay :payId="payId" :payPrice="payPrice"></MsgPay>

  <modal ref="media_modal" id="media_modal" dialogClass="modal-fullscreen-lg-down" tabindex="-1" title="選擇媒體庫圖片" aria-labelledby="modalsLabel" aria-hidden="true">
    <model-header>
      <h5 class="modal-title" id="modalsLabel">選擇媒體庫圖片</h5>
      <button :dismissable="true" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close" @click="cancelMediaImg">
        <i class="ri-close-fill"></i>
      </button>
    </model-header>
    <model-body style="max-height: calc(100vh - 240px);overflow-y: scroll;">
      <div v-if="profileImages.length >=1" class="row images">
        <div class="col-4 mb-2 col-lg-3 col-md-4" v-for="(item, index) in profileImages" :key="`profileImages${index}`">
          <div @click="selectMediaImg(item)" class="user-images position-relative overflow-hidden">
            <a href="javascript:void(0)" style="position: relative;display: block;" ref="imgElement">
              <img :src="item.media_square_url" :data-source="item.media_url" class="img-fluid rounded" style="width: 100%;object-fit: cover;" :style="`height: ${mediaWidth}px`"/>
              <img v-if="selectMedia.some(innerItem => innerItem.media_id === item.media_id)" src="@/assets/images/check_img.png" class="check_img" alt="">
            </a>
          </div>
        </div>
        </div>
        <div v-else class="card card-block card-stretch card-height">
        <div class="card-body text-center">{{ $t('label.noInformation') }}</div>
        </div>
    </model-body>

    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" :dismissable="true" data-bs-dismiss="modal" aria-label="Close" @click="cancelMediaImg">{{ $t('label.cancel') }}</button>
      <button type="button" class="btn btn-primary" @click="media_modal.hide()">{{ $t('button.confirm') }}</button>
    </div>
    <!-- <modal-footer>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" :dismissable="true" data-bs-dismiss="modal" aria-label="Close" @click="cancelMediaImg">{{ $t('label.cancel') }}</button>
        <button type="button" class="btn btn-primary" @click="media_modal.hide()">{{ $t('button.confirm') }}</button>
      </div>
    </modal-footer> -->
  </modal>
</template>

<script>
import { computed, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import ToggleButton from '../../../components/custom/Chat/ToggleButton'
import { sendMessage, chatDetail, sendChatGroup } from '@/api/chat'
import { commomData } from '@/utils/commonVmodel'
import { useDate } from '@/utils/useDate'
import { useCommon } from '@/utils/useCommon'
import { onMounted } from 'vue'
import { useSocket } from '@/utils/useSocket'
// import { Lock, VideoCamera, ChatLineSquare } from '@element-plus/icons-vue'
import { Lock } from '@element-plus/icons-vue'
// import videoPlay from 'vue3-video-play'
// import { Search } from '@element-plus/icons-vue'
import MsgPay from '@/components/custom/dialog/MsgPay'
import VideoPlayer from '../Social/Components/VideoPlayer.vue'
import { getMedia } from '@/api/media'
import Pagination from '@/components/bootstrap/pagination/pagination.vue'

export default {
  name: 'Index',
  // components: { ToggleButton, Lock, VideoCamera, ChatLineSquare },
  // components: { Search, ToggleButton, Lock, videoPlay },
  components: { ToggleButton, Lock, MsgPay, VideoPlayer, Pagination },

  setup() {
    const { initSocket } = useSocket()
    const dialogPayVisible$ = computed(() => store.state.User.dialogPayVisible)

    const keyWork = ref('')
    onMounted(() => {
      store.dispatch('User/fetcGroupChatListAction')
      store
        .dispatch('User/fetcChatListAction')
        .then(result => {
          console.log(
            'SocialApp.vue',
            'User/fetcChatListAction => initSocket',
            result
          )
          initSocket()
        })
        .catch(err => {
          console.log(err)
        })
    })
    const getOption = r => {
      return {
        width: '100%', //播放器高度
        height: '100%', //播放器高度
        color: '#818181', //主题色
        muted: false, //静音
        webFullScreen: false,
        speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'], //播放倍速
        autoPlay: false, //自动播放
        loop: false, //循环播放
        mirror: false, //镜像画面
        ligthOff: false, //关灯模式
        volume: 0.3, //默认音量大小
        control: true, //是否显示控制器
        title: '', //视频名称
        src: r.media_img, //视频源
        poster: r.media_square_img, //封面
        preload: 'meta',
        controlBtns: ['fullScreen'] //显示所有按钮,
      }
    }
    const token = computed(() => store.state.User.token)
    const user = computed(() => store.state.User.user)

    const { w } = useCommon()
    const { formatDate } = useDate()
    const dialogVisible = ref(false)
    const uploadLoading = ref(false)
    const uploadPercent = ref('0%')
    const dialogUrl = ref('')
    const dialogType = ref('')
    const mediaList = ref([])
    const chatListLoading = ref(true)
    const priceDialog = ref(false)
    const chatListDialog = ref(false)
    const checkAll = ref(false)
    const isChat = ref(false)

    const store = useStore()
    // const list = computed(() => store.state.User.chatList)
    const list = computed(() => store.state.User.chatList)
    const groupList = computed(() => store.state.User.groupChatList)

    // const filteredList = computed(() => {
    //   return store.state.User.chatList.filter(v => {
    //     console.log(v)
    //     return keyWork.value
    //       .toLowerCase()
    //       .split(' ')
    //       .every(v => v.account.toLowerCase().includes(v))
    //   })
    // })

    const ruleFormRef = ref(null)
    const form = ref({
      price: 0
    })
    const isIndeterminate = ref(true)
    const activeName = ref('first')
    const isSetPrice = ref(false)
    const handleBeforeUpload = m => {
      console.log('handleBeforeUpload', m)
      uploadLoading.value = true
    }
    const selectChatId = ref([])
    const createFormData = () => ({
      post_content: '',
      msg_media: []
    })

    const postForm = reactive(createFormData())

    // 媒體庫
    const media_modal = ref(false)
    const selectMedia = ref([])
    const profileImages = ref([])

    const handleAvatarSuccess = (response, uploadFile) => {
      uploadLoading.value = false
      console.log(response, uploadFile)
      const d = {
        media_id: '',
        media_type: response.file.file_type === 'video' ? 'video' : 'photo',
        media_url: response.file.file_url,
        media_img: response.file.file_path,
        media_video: response.file.file_video_url
          ? response.file.file_video_url
          : ''
      }
      postForm.msg_media.push(d)
    }

    const handleProgress = event => {
      console.log('event', event.percent)
      if (event.percent > 1 && event.percent < 100) {
        uploadLoading.value = true
        uploadPercent.value = `${Math.round(event.percent)}%`
      }
    }

    const handleRemove = (uploadFile, uploadFiles) => {
      console.log(uploadFile, uploadFiles)
      postForm.value.msg_media = postForm.value.msg_media.filter(element => {
        const c = !element.message_template_media_url
          ? element.message_template_media_img
          : element.message_template_media_url
        return c !== uploadFile.url
      })
    }

    const handlePictureCardPreview = uploadFile => {
      dialogUrl.value = !uploadFile.url
      dialogVisible.value = true
    }

    const onDelete = index => {
      postForm.msg_media.splice(index, 1)
    }

    const onPreview = m => {
      console.log(m)
      if (m.media_type === 'video') {
        dialogUrl.value = m.media_video
        dialogType.value = m.media_type
      } else {
        dialogUrl.value = m.media_url
        dialogType.value = m.media_type
      }
      dialogVisible.value = !dialogVisible.value
    }

    const submit = () => {
      ruleFormRef.value.validate(valid => {
        console.log(valid)
        if (!valid) return
        isSetPrice.value = true
        priceDialog.value = false
      })
    }

    const removePrice = () => {
      form.value.price = 0
      priceDialog.value = false
      isSetPrice.value = false
    }

    setTimeout(() => {
      chatListLoading.value = false
    }, 1000)

    const all = val => {
      console.log('val', val)
      if (val) {
        groupList.value.forEach(e => {
          console.log(e)
          selectChatId.value.push(e.chat_id)
        })
      } else {
        selectChatId.value = []
      }

      isIndeterminate.value = false
    }

    const handleCheckedChange = () => {
      const checkedCount = selectChatId.value.length
      checkAll.value = checkedCount === list.value.length
      isIndeterminate.value =
        checkedCount > 0 && checkedCount < list.value.length
    }

    const actionChat = v => {
      if (selectChatId.value.length === 0) {
        chatListDialog.value = false
        return
      }
      if (!v) {
        selectChatId.value = []
      } else {
        isChat.value = v
      }
      chatListDialog.value = false
    }

    const removeChat = () => {
      selectChatId.value = []
      isChat.value = false
    }

    const openMediaModal = () => {
      media_modal.value.show()
    }

    const getMediaList = () => {
      const obj = {
        sort_by: 'desc',
        order_by: 'created_at',
        page_size: 999,
        page: 1,
        media_category_id: -1
        // type: 'photo',
      }

      getMedia(obj)
        .then(res => {
          profileImages.value = res.data
        })
        .catch(() => {
          // $toast.show(err.response.data.message)
        })
    }

    const selectMediaImg = obj => {
      if (selectMedia.value.some(item => item.media_id === obj.media_id)) {
        selectMedia.value = selectMedia.value.filter(
          item => item.media_id !== obj.media_id
        )
        return
      }
      selectMedia.value.push(obj)
    }

    const cancelMediaImg = () => {
      selectMedia.value = []
    }

    const onMediaPreview = m => {
      if (m.media_type === 'video') {
        dialogUrl.value = m.media_video
        dialogType.value = m.media_type
      } else {
        dialogUrl.value = m.media_square_url
        dialogType.value = m.media_type
      }

      dialogVisible.value = !dialogVisible.value
    }

    const onMediaDelete = index => {
      selectMedia.value.splice(index, 1)
    }

    onMounted(() => {
      getMediaList()
    })

    return {
      dialogPayVisible$,
      removeChat,
      actionChat,
      isChat,
      checkAll,
      isIndeterminate,
      selectChatId,
      all,
      handleCheckedChange,
      keyWork,
      removePrice,
      form,
      isSetPrice,
      submit,
      priceDialog,
      chatListLoading,
      handleProgress,
      onPreview,
      onDelete,
      formatDate,
      list,
      groupList,
      handleBeforeUpload,
      handleAvatarSuccess,
      handleRemove,
      handlePictureCardPreview,
      uploadLoading,
      uploadPercent,
      dialogUrl,
      dialogType,
      mediaList,
      createFormData,
      postForm,
      token,
      dialogVisible,
      w,
      ruleFormRef,
      getOption,
      chatListDialog,
      user,
      activeName,
      openMediaModal,
      media_modal,
      selectMedia,
      profileImages,
      selectMediaImg,
      cancelMediaImg,
      onMediaPreview,
      onMediaDelete
    }
  },
  data() {
    return {
      page_size: 99999,
      leftBar: true,
      rightBar: true,
      chatStart: false,
      textDisabled: false,
      btnDisabled: false,
      detail: [],
      search: '',
      currentUser: {},
      tabStartItem: true,
      tabUserItem: false,
      msg: '',
      primaryKey: 'created_at',
      commomData: commomData(),
      payId: '',
      payPrice: '',
      mediaWidth: 0
    }
  },
  created() {
    window.$chatIndex = this
  },
  beforeUnmount() {
    this.$store.dispatch('User/setChatObjectAction', {})
  },
  mounted() {
    // this.getRows()
  },
  computed: {},

  methods: {
    purchas(msgItem) {
      console.log('msgItem', msgItem)
      this.payId = msgItem.pay_id
      this.payPrice = msgItem.money
      const item = this.currentUser

      setTimeout(() => {
        this.$store.dispatch('User/dialogItemAction', item)
        this.$store.dispatch('User/dialogPayAction', !this.dialogPayVisible$)
      }, 200)
    },

    // connectSocket(array) {
    //   array.forEach((e) => {
    //     window.Echo.private(`chat.${e.chat_id}`, { isPrivate: true })
    //       .listen('.ChatMsgEvent', (e) => {
    //         // 最新訊息
    //         console.log(`chat.${e.data.chat_id} => 收到 最新訊息`)
    //         console.log(e)
    //       })
    //       .listen('.ChatRoomEvent', (e) => {
    //         // 會員訊息置頂
    //         console.log(`chat.${e.data.chat_id} => 會員訊息置頂`)
    //         console.log(e)
    //       })
    //     console.log(window.Echo)
    //   })
    // },
    goToBottom() {
      setTimeout(() => {
        var element = document.getElementById('chat')
        if (element === null) {
          return false
        }
        element.scrollTop = element.scrollHeight - element.clientHeight
      }, 1)
    },
    enterSend(e) {
      if (this.msg === '\n') {
        // this.$toast.error(`請輸入訊息`)
        this.msg = ''
        return false
      }
      if (e.keyCode === 13) {
        this.send()
        this.msg = ''
      }
    },
    toField() {
      const el = document.querySelector('textarea')
      el.focus()
      // el.setSelectionRange(1, 1)
      el.select()
    },
    async send() {
      let d = {}
      if (
        this.postForm.msg_media.length === 0 &&
        this.selectMedia.length === 0 &&
        this.msg === ''
      ) {
        return false
      }
      /*一般文字訊息*/
      if (!this.isChat) {
        if (
          this.form.price > 0 &&
          this.postForm.msg_media.length === 0 &&
          this.selectMedia.length === 0
        ) {
          this.$swal.mixin().fire({
            icon: 'error',
            text: '付費解鎖需上傳圖片或影片'
          })
        } else {
          d = {
            content: this.msg,
            chat_id: this.currentUser.chat_id,
            pay: {
              money: this.form.price,
              chat_media: this.postForm.msg_media
            }
          }
          if (this.selectMedia.length > 0) {
            d = {
              ...d,
              user_media: this.selectMedia.map(item => {
                return item.media_id
              })
            }
            // d.pay.user_media =  this.selectMedia.map((item) => {
            //     return item.media_id
            //   })
          }
          this.textDisabled = true

          this.btnDisabled = true
          sendMessage(d)
            .then(res => {
              this.goToBottom()
              console.log(res)
              this.isChat = false
              this.isSetPrice = false
              this.postForm.msg_media = []
              this.selectMedia = []
              this.form.price = 0
              this.selectChatId = []
              this.msg = ''
              this.textDisabled = false
              this.btnDisabled = false
              this.toField()
            })
            .catch(err => {
              this.commomData.btnLoader = false
              console.log(err)

              this.$swal.mixin().fire({
                icon: 'error',
                text: err.response.data.message
              })

              this.textDisabled = false

              this.btnDisabled = false
            })
        }
      } else {
        d = {
          content: this.msg,
          chat_id: this.selectChatId?.length
            ? this.selectChatId
            : [this.currentUser.chat_id],
          pay: {
            money: this.form.price,
            chat_media: this.postForm.msg_media
          },
          current_chat_id: this.currentUser.chat_id
        }
        if (this.selectMedia.length > 0) {
          d = {
            ...d,
            user_media: this.selectMedia.map(item => {
              return item.media_id
            })
          }
        }
        this.textDisabled = true

        this.btnDisabled = true
        sendChatGroup(d)
          .then(res => {
            this.goToBottom()
            console.log(res)
            this.isChat = false
            this.isSetPrice = false
            this.postForm.msg_media = []
            this.selectMedia = []
            this.form.price = 0
            this.selectChatId = []
            this.msg = ''
            this.textDisabled = false
            this.toField()
            this.isChat = false
            this.btnDisabled = false
          })
          .catch(err => {
            this.commomData.btnLoader = false
            console.log(err)

            this.$swal.mixin().fire({
              icon: 'error',
              text: err.response.data.message
            })

            this.textDisabled = false

            this.btnDisabled = false
          })
      }
    },
    back() {
      // this.leftBar = true
      // this.rightBar = false
      this.removeChat()
      this.currentUser = {}
      this.payId = ''
      this.payPrice = ''
      this.$store.dispatch('User/setChatObjectAction', {})

      this.detail = []
      if (window.innerWidth <= 991) {
        this.leftBar = true
        this.rightBar = false
      } else {
        this.leftBar = true
        this.rightBar = false
        this.chatStart = true
      }
    },
    openUser(item) {
      if (window.innerWidth <= 991) {
        this.leftBar = false
        this.rightBar = true
      } else {
        this.leftBar = true
        this.rightBar = true
        this.chatStart = false
      }
      this.detail = []

      this.currentUser = item
      this.tabStartItem = false
      this.tabUserItem = true
      this.$store.dispatch('User/setChatObjectAction', item)
      const d = {
        sort_by: 'asc',
        order_by: 'datetime',
        page_size: this.page_size,
        page: 1,
        chat_id: this.currentUser.chat_id
      }
      chatDetail(d)
        .then(res => {
          console.log('new=>res.data', res.data)
          console.log('old=>this.detail', this.detail)
          if (this.detail.length >= 1) {
            // let difference = this.detail.filter(x => res.data.includes(x.pay_id));
            let difference = res.data.filter(n =>
              this.detail.every(o => !o.pay_id.includes(n.pay_id))
            )
            console.log('difference=>', difference)
            this.detail = this.detail.concat(difference)
          } else {
            this.detail = res.data
          }
          this.commomData.btnLoader = false
          this.btnDisabled = false
          this.goToBottom()
        })
        .catch(err => {
          this.commomData.btnLoader = false
          this.btnDisabled = false
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    },
    checkUser(item, type) {
      const user = this.usersList.find(user => user.id === item)
      let final
      if (user !== undefined) {
        switch (type) {
          case 'name':
            final = user.name
            break
          case 'image':
            final = user.image
            break
          case 'role':
            final = user.role
            break
        }
        return final
      }
      return require('../../../assets/images/user/user-5.jpg')
    },
    // async getRows() {
    //   if (this.commomData.noResult) {
    //     return false
    //   }
    //   this.commomData.condition.order_by = this.primaryKey
    //   this.commomData.condition.post_type = 1
    //   this.commomData.condition.page_size = this.page_size
    //   this.commomData.condition.user_account = this.$route.params.user_account
    //   await chatList(this.commomData.condition)
    //     .then((res) => {
    //       // this.connectSocket(res.data)
    //       this.list = res.data

    //       // if (this.commomData.condition.page === 1) {
    //       //   this.list = res.data

    //       //   this.commomData.condition.page = 2
    //       //   this.commomData.listLoader = false
    //       //   if (res.total === 0) {
    //       //     this.commomData.noResult = true
    //       //   }
    //       //   this.connectSocket(res.data)
    //       // } else if (res.total > this.list.length) {
    //       //   this.list.push(...res.data)
    //       //   this.commomData.condition.page = this.commomData.condition.page + 1
    //       //   this.commomData.listLoader = false
    //       //   this.connectSocket(res.data)
    //       // } else if (res.total === this.list.length) {
    //       //   this.commomData.noResult = true
    //       //   this.commomData.listLoader = false
    //       // }
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
    // },
    showLoading() {
      // this.commomData.listLoader = true
      // setTimeout(() => {
      //   this.commomData.listLoader = false
      // }, 500)
    },
    setVideowWatermark(val) {
      this.handleVideoClick(val)
    },
    handleVideoClick() {
      // console.log(1, refValue)
      // const video = this.$refs[`videoPlayer_${refValue}`][0]
      // console.log(video)
      // if (!video.paused && !video.ended) {
      // const i = this.$refs[`videoPlayerI_${refValue}`][0]
      // console.log(i)
      // i.style.display = 'block'
      // console.log('视频正在播放')
      // this.stopMovingText()
      // const vm = this // 保存Vue实例的引用
      // this.intervalId = setInterval(() => {
      //   vm.moveText(refValue) // 在箭头函数中调用moveText函数
      // }, 3000)
      // } else {
      //   console.log('视频未在播放')
      // }
    },
    videoOptions(r) {
      return {
        // width: 500,
        // height: 300,
        autoplay: false,
        controls: true,
        sources: [
          {
            src: r.media_img
            // type: 'video/mp4'
          }
        ],
        poster: r.media_square_img,
        userActions: {
          doubleClick: false
        },
        playbackRates: [1, 1.5, 2],
        controlBar: {
          // fullscreenToggle: true,
          pictureInPictureToggle: false
        },
        isFullScreen: true
      }
    },
    getMediaElement() {
      setTimeout(() => {
        this.mediaWidth = this.$refs.imgElement[0].clientWidth
      }, 500)
    }
  }
}
</script>

<style>
.remove-toggle::after {
  content: unset;
}

.el-checkbox__label {
  display: none;
}
.chat-page {
  padding: 1.25rem 1.25rem 3rem;
}
.bg_img {
  height: 100%;
  width: 100%;
}
.img_hide {
  position: absolute;
  height: 100%;
  left: 0;
  opacity: 0;
}
.check_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.9);
}
.el-dialog img {
  width: 60%;
}
</style>
